import React from "react";
import { IconButton } from "@material-ui/core";
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import flag1 from "../../../assets/images/kenya-flag.svg";
import flag2 from "../../../assets/images/ugandan-flag.svg";
import { countryFlagStyle } from "./styles";

const CountryFlag = () => {
  const classes = countryFlagStyle();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };



  return (

<div>
  <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
  <img src={flag1} alt={"Kenyan Flag"} />
  </Button>
  <Menu
  id="simple-menu"
  anchorEl={anchorEl}
  keepMounted
  open={Boolean(anchorEl)}
  onClose={handleClose}
  className={classes.flagListbox}
  >
      <IconButton onClick={handleClose} disableRipple href="https://vehicle.co.ke" target="_self"  title="Kenya">
      <img width={35} src={flag1} alt={"Kenyan Flag"} />
      </IconButton>

      <IconButton onClick={handleClose} disableRipple href=" https://vehicle.co.ug" target="_self" title="Uganda">
      <img width={35} src={flag2} alt={"Ugandan Flag"} />
      </IconButton>

   </Menu>
</div>

  );


};

CountryFlag.propTypes = {};

export default CountryFlag;
