import React from "react";
import { AppBar, Toolbar, Container, Typography } from "@material-ui/core";

// import Language from "../../language";
import CountryFlag from "./countryFlag";
import Social from "../../social";
import topHeaderStyles from "./styles";
import phone from "../../../assets/images/phone.svg";

const TopHeader = () => {
  const classes = topHeaderStyles();
  return (
    <AppBar component="div" position="static" color="secondary" elevation={0}>
      <Container fixed>
        <Toolbar disableGutters className={classes.topStrip}>
          <div className={classes.leftContainer}>
            <div className={classes.textContainer}>
              <div className={classes.iconContainer}>
                  <img src={phone} alt="Vehicle" />
              </div>
              <div className={classes.labelContainer}>
                <Typography className={classes.text}>
                <span  className={classes.hideMobile} display={{ xs: 'none', sm: 'block' }}>Contact Center:</span>
                  <Typography component="a" href="tel:  +254 709 783 000" className={classes.helperText}>
                  +254 709 783 000
                  </Typography>
                </Typography>
              </div>
            </div>
          </div>
          <div className={classes.rightContainer}>
            <Social />
            {/* <Language /> */}
            <CountryFlag/>
          </div>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

TopHeader.propTypes = {};

export default TopHeader;
